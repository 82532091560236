.item_box .iconfont icon-cuowu[data-v-ce38a674] {
  color: #f56c6c;
}
.item_box .iconfont icon-duihao[data-v-ce38a674] {
  color: #67c23a;
}
.item_box .iconfont icon-cuowu[data-v-ce38a674] {
  color: #e6a23c;
}
.item_box .item[data-v-ce38a674] {
  text-align: center;
}
.item_box .item i[data-v-ce38a674] {
  font-size: 13px;
}
.item_box .item span[data-v-ce38a674] {
  display: inline-block;
  font-size: 13px;
  color: #666;
  margin-left: 4px;
}
.top_button[data-v-ce38a674] {
  width: 100%;
  margin: 0 auto;
  text-align: center;
}
.leftPadding[data-v-ce38a674] {
  padding: 20px;
  background: #f2f9fb;
}
.leftPadding2[data-v-ce38a674] {
  padding: 20px;
  position: relative;
  background: #f2f9fb;
}
.circle[data-v-ce38a674] {
  animation-duration: 1s;
  animation-name: mymove-ce38a674;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  position: absolute;
  top: -230px;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
.circle2[data-v-ce38a674] {
  position: absolute;
  top: -230px;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 230px;
  height: 230px;
}
@keyframes mymove-ce38a674 {
from {
    width: 230px;
    height: 230px;
    transform: rotate(0deg);
}
to {
    width: 230px;
    height: 230px;
    transform: rotate(360deg);
}
}
.shield[data-v-ce38a674] {
  position: absolute;
  top: -230px;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 140px;
  height: 140px;
}
.shield3[data-v-ce38a674] {
  position: absolute;
  top: -222px;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 240px;
  height: 240px;
}
.top_button2[data-v-ce38a674] {
  position: absolute;
  top: 512px;
  bottom: 0;
  left: 25px;
  right: 0;
  margin: auto;
  width: 250px;
  height: 130px;
  text-align: center;
}
.top_button3[data-v-ce38a674] {
  position: absolute;
  top: 512px;
  bottom: 0;
  left: 9px;
  right: 0;
  margin: auto;
  width: 252px;
  height: 130px;
  text-align: center;
}
.fengxian[data-v-ce38a674] {
  position: absolute;
  top: -179px;
  left: 10px;
  right: 0;
  bottom: 0;
  width: 38px;
  height: 86px;
  margin: auto;
  z-index: 9;
  font-size: 13px;
  color: var(--themeColor, #17a2b8);
}
.fengxian2[data-v-ce38a674] {
  position: absolute;
  top: -191px;
  left: 2px;
  right: 0;
  bottom: 0;
  width: 38px;
  height: 86px;
  margin: auto;
  z-index: 9;
  font-size: 19px;
  color: var(--themeColor, #17a2b8);
}
.fengxian5[data-v-ce38a674] {
  position: absolute;
  top: -208px;
  left: 2px;
  right: 0;
  bottom: 0;
  width: 38px;
  height: 86px;
  margin: auto;
  z-index: 9;
  font-size: 18px;
  color: var(--themeColor, #17a2b8);
}
.fengxian3[data-v-ce38a674] {
  position: absolute;
  top: -165px;
  left: 2px;
  right: 0;
  bottom: 0;
  width: 38px;
  height: 86px;
  margin: auto;
  z-index: 9;
  font-size: 19px;
  color: var(--themeColor, #17a2b8);
}
.fengxian4[data-v-ce38a674] {
  position: absolute;
  top: -171px;
  left: 2px;
  right: 0;
  bottom: 0;
  width: 60px;
  height: 86px;
  margin: auto;
  z-index: 9;
  font-size: 19px;
  color: var(--themeColor, #17a2b8);
}
.descripBody[data-v-ce38a674] {
  padding: 12px;
}
.titleSpan[data-v-ce38a674] {
  color: var(--themeColor, #17a2b8);
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 10px;
  display: flex;
  margin-top: 10px;
  align-items: center;
  justify-content: space-between;
}
.bodyTitle[data-v-ce38a674] {
  border-top: 1px solid var(--themeColor, #17a2b8);
}
.cell-item[data-v-ce38a674] {
  font-size: 13px;
}
.div[data-v-ce38a674] {
  display: flex;
  align-items: center;
  margin-top: 10px;
}
.title_div[data-v-ce38a674] {
  background-color: #13a2b8;
  width: 5px;
  height: 20px;
  margin-left: 10px;
}
.title_p[data-v-ce38a674] {
  font-size: 30px;
}
.list_div[data-v-ce38a674] {
  width: 99%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  /* border: 1px solid; */
  margin-left: 10px;
}
.div_box[data-v-ce38a674] {
  border: 1px solid #e6e6e6;
  display: flex;
  width: 24%;
  height: 100px;
  margin-bottom: 10px;
}
.cloumn_div[data-v-ce38a674] {
  display: flex;
  flex-direction: column;
  padding-top: 5px;
  margin-left: 10px;
  width: 55%;
}
.title_span[data-v-ce38a674] {
  font-size: 13px;
  font-weight: 550;
}
.line_div[data-v-ce38a674] {
  width: 60px;
  height: 3px;
  background-color: #13a2b8;
  margin-top: 5px;
}
.under_div[data-v-ce38a674] {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}
.span_div[data-v-ce38a674] {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  cursor: pointer;
}
.span_div span[data-v-ce38a674] {
  font-size: 12px;
}
.h_div[data-v-ce38a674] {
  display: flex;
  justify-content: space-between;
}
.h_div2[data-v-ce38a674] {
  display: flex;
}
.span[data-v-ce38a674] {
  font-size: 13px;
  font-weight: 570;
  color: #13a2b8;
  margin-top: 7px;
  text-align: right;
  cursor: pointer;
}
.title_div2[data-v-ce38a674] {
  display: flex;
  flex-direction: column;
  margin-top: 6px;
  margin-left: 20px;
}
.div_boxs[data-v-ce38a674] {
  border: 1px solid #e6e6e6;
  display: flex;
  width: 24%;
  height: 100px;
  margin-bottom: 10px;
}
.column2[data-v-ce38a674] {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  margin-top: 6px;
  width: 55%;
}
.spanes[data-v-ce38a674] {
  font-size: 12px;
}
.div2[data-v-ce38a674] {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  font-size: 12px;
  cursor: pointer;
}
.under_div_right[data-v-ce38a674] {
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  padding-right: 5px;
}
.button_el[data-v-ce38a674] {
  margin-top: 30px;
}
.titleLeft[data-v-ce38a674]::before {
  content: "";
  background: var(--themeColor, #17a2b8);
  display: inline-block;
  width: 3px;
  height: 13px;
  position: relative;
  margin-right: 10px;
}
.titleLeft[data-v-ce38a674] {
  padding-left: 12px;
  color: #333;
}
.check_style[data-v-ce38a674] {
  padding-left: 5px;
}
.main_list[data-v-ce38a674] {
  display: flex;
}
.each_info[data-v-ce38a674] {
  width: 49%;
}
.each_info[data-v-ce38a674]:first-child {
  padding-right: 5px;
  border-right: 1px solid #efefef;
}
.info_title[data-v-ce38a674] {
  width: 99%;
  color: var(--themeColor, #17a2b8);
  font-weight: 600;
  font-size: 14px;
  padding-bottom: 10px;
  display: inline-block;
  border-bottom: 1px solid #efefef;
}
.each_title[data-v-ce38a674] {
  width: 98%;
  padding: 7px 0;
  border-bottom: 1px solid #efefef;
  font-size: 12px;
  margin-left: 1%;
}
.each_title[data-v-ce38a674]::before {
  content: "";
  background: var(--themeColor, #17a2b8);
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  position: relative;
  margin-right: 10px;
}
.each_hint[data-v-ce38a674] {
  width: 98%;
  padding: 7px 0;
  border-bottom: 1px solid #efefef;
  font-size: 14px;
  margin-left: 1%;
  display: flex;
  justify-content: space-between;
}
.hint_title[data-v-ce38a674]::before {
  content: "";
  background: var(--themeColor, #17a2b8);
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  position: relative;
  margin-right: 10px;
}
.iconfont[data-v-ce38a674] {
  margin-left: 4px;
  font-size: 13px;
}
.dialog_table[data-v-ce38a674] {
  width: 100%;
  margin: 0 auto;
  border: 1px solid #ccc;
  border-bottom: 0;
}
.dialog_table tr[data-v-ce38a674] {
  line-height: 32px;
  height: 32px;
}
.dialog_table td[data-v-ce38a674] {
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  padding: 0 10px;
  font-size: 14px;
}
.dialog_table td[data-v-ce38a674]:last-child {
  border-right: 0;
}
.hint_title[data-v-ce38a674] {
  width: 22%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: left;
  font-size: 12px;
}
.hint_right[data-v-ce38a674] {
  width: 95%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: right;
  font-size: 12px;
}
[data-v-ce38a674] .el-button {
  font-size: 12px !important;
}
.info_right[data-v-ce38a674] {
  width: 78%;
  display: flex;
  align-items: center;
}
.next[data-v-ce38a674] {
  cursor: pointer;
  position: absolute;
  top: 28px;
  font-size: 12px;
  color: #3c6ff2;
  right: 0;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
}